<template>
  <div>
    <b-card title="Warehouse">
      <b-overlay
        id="overlay-background"
        :show="loading"
        blur="10px"
        opacity="0.30"
        rounded="sm"
        variant="light"
      >
        <ValidationObserver ref="simpleRules">
          <b-form>
            <b-row>
              <b-col
                cols="12"
                md="6"
                class="mt-1"
              >
                <b-form-group>
                  <ValidationProvider
                    #default="{ errors }"
                    name="Name"
                    rules="required"
                  >
                    <v-select
                      v-model="warehouse_selected"
                      :options="warehouses"
                      :reduce="option => option.id"
                      label="name"
                      :state="errors.length > 0 ? false:null"
                      placeholder="Select Warehouse"
                      @input="handleChange"
                    >
                      <template v-slot:option="option">
                        {{ option.name }}
                      </template>
                    </v-select>
                    <small class="text-danger">{{ errors[0] }}</small>
                  </ValidationProvider>
                </b-form-group>
              </b-col>
              <b-col
                md="3"
                class="mt-md-1"
              >
                <b-button
                  v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                  variant="outline-primary"
                  :to="{ name: 'warehouse-dashboard', params: { id: warehouse_selected, name: warehouseName } }"
                >
                  <feather-icon
                    icon="CornerLeftDownIcon"
                    class="mr-50"
                  />
                  <span class="align-middle">Get Data</span>
                </b-button>
              </b-col>
            </b-row>
          </b-form>
        </ValidationObserver>
      </b-overlay>
    </b-card>
  </div>
</template>

<script>
import {
  BForm,
  BCard,
  BRow,
  BCol,
  BButton,
  BOverlay,
  BFormGroup,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import Ripple from 'vue-ripple-directive'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required } from '@validations'
import { RepositoryFactory } from '@/repository/RepositoryFactory'

const ResourceRepository = RepositoryFactory.get('resource')

export default {
  directives: {
    Ripple,
  },
  components: {
    ValidationProvider,
    ValidationObserver,
    BFormGroup,
    BCard,
    BRow,
    BCol,
    BButton,
    BOverlay,
    vSelect,
    BForm,
  },
  data() {
    return {
      required,
      loading: false,
      warehouses: [],
      warehouse_selected: null,
      warehouseName: '',
    }
  },
  mounted() {
    this.readDataforState()
  },
  methods: {
    async readDataforState() {
      try {
        this.loading = true
        this.warehouses = localStorage.getItem('assigned_warehouse_list') ? JSON.parse(localStorage.getItem('assigned_warehouse_list')) : (await ResourceRepository.getAssignedWarehousesNoPagination()).data.data
        if (!localStorage.getItem('assigned_warehouse_list')) localStorage.setItem('assigned_warehouse_list', JSON.stringify(this.warehouseList))

        if (this.warehouses.length === 1) {
          this.warehouse_selected = this.warehouses[0].id
          this.warehouseName = decodeURIComponent(this.warehouses[0].name).replace(/ /g, '-')
          await this.$router.push({ name: 'warehouse-dashboard', params: { id: this.warehouse_selected, name: this.warehouseName } })
        }
      } catch (e) {
        this.convertAndNotifyError(e)
      }
      this.loading = false
    },
    handleChange() {
      const selectedrow = this.warehouses.find(row => row.id === this.warehouse_selected)
      this.warehouseName = decodeURIComponent(selectedrow.name).replace(/ /g, '-')
    },
  },
}
</script>

<style lang="scss">
@import '../../@core/scss/vue/libs/vue-select.scss';
</style>
